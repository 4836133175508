<template>
  <div class="pa-2">
    <v-card-text class="py-0 my-n3 text-right">
      <v-row>
        <v-col>
          <v-text-field
            v-model="searchQuery"
            placeholder="Search"
            prepend-inner-icon="mdi-magnify"
            rounded
            hide-details
            outlined
            dense
          >
          </v-text-field>
        </v-col>
        <v-col class="shrink">
          <v-dialog
            v-model="editVehicleShow"
            max-width="650"
            transition="dialog-top-transition"
            persistent
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn rounded right color="primary" v-bind="attrs" v-on="on">
                Add Vehicle
              </v-btn>
            </template>
            <customer-vehicle-edit
              v-if="editVehicleShow"
              :vehicleMappingProp="{}"
              :canSearchVehicleProp="true"
              @cancelVehicleMappingEdit="editVehicleShow = false"
              @updateVehicleMapping="updateVehicleMapping"
            >
            </customer-vehicle-edit>
          </v-dialog>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text>
      <div v-if="loading && !initialized" class="mt-6">
        <box-view-skeleton-loader
          v-for="i in [1, 2, 3]"
          :key="i"
          class="mb-6"
        />
      </div>
      <customer-vehicle
        :ref="`customerVehicle${item.id}`"
        v-for="item in vehicles"
        :key="item.id"
        :vehicleMappingProp="item"
        :searchQueryProp="searchQueryTokens"
        @updateVehicleMapping="updateVehicleMapping"
      ></customer-vehicle>

      <scroll-pagination
        ref="scrollPagination"
        :loader-method-prop="searchVehicles"
        :loader-disable-prop="vehicles.length >= totalCount"
      ></scroll-pagination>
    </v-card-text>
  </div>
</template>
  
  <script>
import _ from "lodash";
import debounce from "debounce";

import boxViewSkeletonLoader from "@/components/widgets/box-view-skeleton-loader";
import CustomerVehicle from "@/components/customer-vehicle";
import customerVehicleEdit from "@/components/customer-vehicle-edit.vue";
import scrollPagination from "@/components/scroll-pagination";
import { userService } from "@/services";

export default {
  name: "my-garage",
  components: {
    boxViewSkeletonLoader,
    CustomerVehicle,
    customerVehicleEdit,
    scrollPagination,
  },
  props: {
    customerVehicleMappingIdProp: String,
  },
  data: () => ({
    searchQuery: "",
    loading: true,
    initialized: false,

    currentPage: 1,
    totalCount: 0,
    itemsPerPage: 25,

    editVehicleShow: false,
    editVehicleLoading: false,

    vehicles: [],
    sortModel: {
      dir: "desc",
      selectedSort: { text: "Date added", value: "dateCreatedUtc" },
    },
  }),
  computed: {
    searchQueryTokens() {
      return _.split(this.searchQuery, " ");
    }
  },
  async created() {
    this.searchVehicles = debounce(this.searchVehicles, 500);
    await this.searchVehicles();
  },
  watch: {
    searchQuery: {
      handler: async function () {
        this.currentPage = 1;
        this.vehicles = [];
        await this.searchVehicles();
      },
    },
  },
  methods: {
    async searchVehicles() {
      this.loading = true;
      var result = await userService.getVehicles({
        filter: this.searchQuery,
        page: this.currentPage,
        itemsPerPage: this.itemsPerPage,
      });

      this.vehicles.push(...result.data.items);
      this.totalCount = result.data.totalDisplayItems;
      this.currentPage++;
      this.loading = false;
      this.initialized = true;
    },
    updateVehicleMapping(vehicleMapping) {
      this.editVehicleShow = false;
      var index = _.findIndex(this.vehicles, ["id", vehicleMapping.id]);
      if (index !== -1) {
        this.vehicles.splice(index, 1, vehicleMapping);
        this.vehicles = [...this.vehicles];
      } else {
        this.vehicles.unshift(vehicleMapping);
      }
    },
  },
};
</script>
  
  <style scoped>
@import "../assets/scss/vuetify-elements/card.scss";
@import "../assets/scss/vuetify-elements/sheet.scss";

.page-header {
  position: relative;
}

.smc-breadcrumbs {
  padding-left: 12px;
}

.smc-breadcrumbs .smc-breadcrumbs-item {
  font-weight: 600;
}
</style>
  
  <style lang="scss" scoped>
.sort-container {
  text-align: right;

  @media screen and (min-width: 601px) {
    position: absolute;
    right: 16px;
    top: 26px;
  }
}
</style>