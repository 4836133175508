<template>
  <v-sheet elevation="2" white style="border-radius: 32px;" height="270">
    <v-row>
      <v-col>
        <v-skeleton-loader class="px-2" type="table-heading"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-skeleton-loader class="px-2" type="image" height="140"></v-skeleton-loader>
      </v-col>
      <v-col>
        <v-skeleton-loader type="paragraph"></v-skeleton-loader>
        <v-skeleton-loader type="paragraph"></v-skeleton-loader>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col>
        <v-skeleton-loader type="paragraph"></v-skeleton-loader>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col>
        <v-skeleton-loader class="mt-4 ml-10" width="100" type="text"></v-skeleton-loader>
        <v-skeleton-loader class="mt-4 ml-10" type="chip"></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
    name: "box-view-skeleton-loader"
}
</script>