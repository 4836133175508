export default class AuthenticateLogin {
    constructor(
        username,
        emailAddress,
        password
    ) {
        this.username = username;
        this.emailAddress = emailAddress;
        this.password = password;
    }
};