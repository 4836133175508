<template>
    <v-card color="white">
        <v-card-title class="smc-card-dialog-title-inset primary white--text py-2">
            Current Active Jobs
        </v-card-title>
        <v-card-text class="pt-3">
            <v-sheet v-if="loading" class="text-center">
                <v-progress-circular :size="25" indeterminate color="primary" class="mx-auto"></v-progress-circular>
            </v-sheet>
            <v-sheet v-else>
                <v-card v-for="quote in quotes" v-bind:key="quote.id" color="white" class="smc-card-dialog mb-4"
                    style="min-width: 400px; max-width: 550px;">
                    <v-card-title class="smc-card-dialog-title-inset primary white--text">
                        {{ quote.quoteRequestModel.reference }}
                    </v-card-title>
                    <v-card-subtitle class="smc-card-dialog-subtitle-inset">
                        {{ quoteStatus(quote.quoteStatus).label }}
                    </v-card-subtitle>
                    <v-card-text class="pt-4">
                        <v-row>
                            <v-col class="shrink">
                                <img :src="quote.workshop.logoUrl" class="smc-search-result-image"
                                    style="max-width: 200px;" />
                            </v-col>
                            <v-col>
                                <v-row>
                                    <v-col class="text-left">
                                        <h3>
                                            {{ quote.workshop.name }}
                                        </h3>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="text-left">
                                        <v-label>Selected service date</v-label>
                                    </v-col>
                                    <v-col class="text-right">
                                        <v-chip small outlined color="secondary">
                                            {{ dateHelper.formatDateLong(quote.serviceDate) }}
                                        </v-chip>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="text-left">
                                        <v-btn outlined color="primary" rounded
                                            :to="`/quotes/${quote.quoteRequestModel.uniqueId}`">Open</v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-sheet>
        </v-card-text>
    </v-card>
</template>

<script>
import _ from "lodash";
import { quoteStatuses, dateHelper } from "@/helpers";
import { quoteService } from "@/services";

export default {
    name: "customer-vehicle-active-quotes",
    props: {
        vehicleIdProp: Number
    },
    data: () => ({
        dateHelper,
        loading: false,
        initialized: false,
        quotes: [],
    }),
    async created() {
        if (!this.initialized) {
            await this.loadQuotes();
        }
    },
    methods: {
        async loadQuotes() {
            this.loading = true;
            this.quotes = await quoteService.getCustomerVehicleActiveQuotes(this.vehicleIdProp);
            this.loading = false;
            this.initialized = true;
        },
        quoteStatus(quoteStatus) {
            var status = _.find(quoteStatuses, { id: quoteStatus });
            return status;
        }
    }
}
</script>

<style lang="scss" scoped>
.smc-card-dialog.v-card > .v-card__title.smc-card-dialog-title-inset {
  width: fit-content;
  min-width: 250px;
  padding: 10px 20px;
  border-top-left-radius: 30px !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 32px !important;

  @media screen and (max-width: 600px) {
    width: 100%;
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 30px !important;
  }
}

.smc-card-dialog.v-card > .v-card__subtitle.smc-card-dialog-subtitle-inset {
  @media screen and (max-width: 600px) {
    width: 100%;
    position: relative;
    border-bottom-right-radius: 32px;
  }
}
</style>