<template>
  <v-sheet>
    <v-card-text>
      <v-row>
        <v-col class="shrink">
          <v-avatar class="ma-3" size="125" width="90%" tile>
            <img :src="vehicleProp.imageUrl" class="smc-search-result-image" />
          </v-avatar>
        </v-col>
        <v-col class="text-left">
          <div class="ma-3">
            <h3>
              {{ vehicleProp.vehicleSummary }}
            </h3>
            <v-divider></v-divider>
            <strong>Minor Service Interval:</strong>
            {{ vehicleProp.serviceInterval1 | numeral("0,0") }} KM<br />
            <strong>Major Service Interval:</strong>
            {{ vehicleProp.serviceInterval2 | numeral("0,0") }} KM
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text class="pt-4">
      <v-card class="px-6" elevation="6">
        <line-chart-generator
          :chart-options="{
            responsive: true,
            maintainAspectRatio: false,
          }"
          :chart-data="mileageHistoryDataComputed"
          style="min-height: 300px"
        />
      </v-card>
    </v-card-text>
    <v-card-text>
      <v-row v-if="!serviceCheckLoaded">
        <v-col class="text-right py-0">
          <v-btn
            color="info"
            outlined
            rounded
            right
            small
            :loading="serviceCheckLoading"
            @click="loadServiceCheck"
          >
            Check service schedule
          </v-btn>
        </v-col>
      </v-row>
      <v-card elevation="6" v-else>
        <v-card-subtitle>Estimated Service Schedule</v-card-subtitle>
        <v-alert
          v-if="serviceSchedule.promptService"
          :type="serviceSchedule.promptColor"
          class="mb-0"
        >
          <v-row>
            <v-col> {{ serviceSchedule.promptText }} </v-col>
            <v-col class="shrink">
              <v-btn small secondary outlined rounded @click="newQuote">
                New Request
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
        <v-card-text class="px-0">
          <v-simple-table>
            <thead class="grey darken-3">
              <tr>
                <th class="white--text text-left">Date</th>
                <th class="white--text text-left">Workshop</th>
                <th class="white--text text-left">Type</th>
                <th class="white--text text-left">Mileage</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in serviceSchedule.pastServices"
                v-bind:key="index"
                class="text-left"
                :class="`border-${item.color}`"
              >
                <td>
                  {{ dateHelper.formatDateLong(item.serviceDate) }}
                </td>
                <td>
                  {{ item.workshopName }}
                </td>
                <td>
                  {{ item.type }}
                  <span>
                    <br />
                    <v-chip
                      v-if="item.trigger"
                      :color="item.color"
                      class="mb-1 ml-n3 pr-6"
                      small
                    >
                      {{ item.trigger }}
                    </v-chip>
                  </span>
                </td>
                <td>{{ item.vehicleMilleage }} KM</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-card-text>
    <v-card-text>
      <v-card elevation="6">
        <v-simple-table>
          <template v-slot:default>
            <thead class="grey darken-3">
              <tr>
                <th class="white--text text-left">Date</th>
                <th class="white--text text-right">Mileage</th>
                <th class="white--text text-right">Average Mileage</th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in mileageHistoryReversed"
                v-bind:key="index"
              >
                <td class="text-left">
                  {{ dateHelper.formatDateLong(item.dateCreated) }}
                </td>
                <td class="text-right">
                  <span v-if="index < mileageHistoryReversed.length - 1">
                    <v-chip color="success" class="px-2 mr-1" outlined small>
                      +
                      {{
                        (mileageHistoryReversed[index].vehicleMileage -
                          mileageHistoryReversed[index + 1].vehicleMileage)
                          | numeral("0,0")
                      }}
                      KM
                    </v-chip>
                  </span>
                  {{ item.vehicleMileage | numeral("0,0") }} KM
                </td>
                <td class="text-right">
                  {{ item.averageDailyMileage | numeral("0,0") }} KM
                </td>
                <td class="px-1">
                  <v-dialog
                    v-model="item.deleteHistoryMileageShow"
                    max-width="550"
                    transition="dialog-top-transition"
                    v-if="(mileageHistoryReversed.length > 1 && index == 0) || mileageHistoryReversed.length == 1"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon color="red" small v-bind="attrs" v-on="on">
                        <v-icon>mdi-delete-outline</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-toolbar color="primary" dark height="48">
                        Confirm
                      </v-toolbar>
                      <v-card-text>
                        <div class="mt-4">
                          Are you sure you want to delete this mileage entry?
                        </div>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          rounded
                          small
                          color="secondary"
                          outlined
                          @click="item.deleteHistoryMileageShow = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          rounded
                          small
                          color="primary"
                          @click="deleteVehicleMileage(item.id)"
                        >
                          Delete
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-card-text>
  </v-sheet>
</template>

<script>
import _ from "lodash";
import { dateHelper } from "@/helpers";
import { userService } from "@/services";

import { Line as LineChartGenerator } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);

export default {
  name: "customer-vehicle-mileage-history",
  components: {
    LineChartGenerator,
  },
  props: {
    mileageHistoryDataProp: Array,
    vehicleProp: Object,
    vehicleMappingProp: Object,
  },
  data: () => ({
    dateHelper,

    serviceCheckLoaded: false,
    serviceCheckLoading: false,

    mileageHistoryData: [],
    serviceSchedule: [],
  }),
  computed: {
    mileageHistoryDataComputed() {
      if (this.mileageHistoryData) {
        let chartData = {
          labels: [],
          datasets: [
            {
              label: "Total Mileage",
              backgroundColor: "#ed3237",
              data: [],
            },
            {
              label: "Mileage Increase",
              backgroundColor: "#333",
              data: [],
            },
          ],
        };
        this.mileageHistoryData.forEach((hist, index) => {
          var mileageIncrease = 0;
          if (index > 0) {
            let previousMileage =
              this.mileageHistoryData[index - 1].vehicleMileage;
            mileageIncrease = hist.vehicleMileage - previousMileage;
          }
          chartData.labels.push(dateHelper.formatDateShort(hist.dateCreated));
          chartData.datasets[0].data.push(hist.vehicleMileage);
          chartData.datasets[1].data.push(mileageIncrease);
        });
        return chartData;
      }
      return [];
    },
    mileageHistoryReversed() {
      return _.orderBy(this.mileageHistoryData, ["dateCreated"], ["desc"]);
    },
  },
  created() {
    this.mileageHistoryData = _.cloneDeep(this.mileageHistoryDataProp);
  },
  watch: {
    mileageHistoryDataProp: function (newVal) {
      this.mileageHistoryData = _.cloneDeep(newVal);
    },
  },
  methods: {
    async loadServiceCheck() {
      this.serviceCheckLoaded = false;
      this.serviceCheckLoading = true;
      let result = await userService.queryVehicleServiceSchedule(
        this.vehicleMappingProp.id
      );
      if (result.status == 200) {
        this.serviceSchedule = result.data;
      }
      this.serviceCheckLoading = false;
      this.serviceCheckLoaded = true;
    },
    newQuote() {
      this.$router.push({
        name: "new-quote",
        params: { vehicleMappingProp: this.vehicleMappingProp },
      });
    },
    async deleteVehicleMileage(id) {
      var result = await userService.deleteVehicleMileage(id);
      if (result.status == 200) {
        this.serviceCheckLoaded = false;
        this.serviceCheckLoading = false;
        this.serviceSchedule = [];

        this.mileageHistoryData = _.cloneDeep(result.data.customerVehicleMappingMileageHistories);
        this.$emit("vehicleMappingUpdated", result.data);
      }
    },
  },
};
</script>

<style>
tr.border-info td {
  border-top: 1px solid #2196f3 !important;
  border-bottom: 1px solid #2196f3 !important;
  color: #2196f3;
}

tr.border-error td {
  border-top: 1px solid #ed3237 !important;
  border-bottom: 1px solid #ed3237 !important;
  color: #ed3237;
}
</style>